import { createContext, useReducer } from 'react';
import { Kroger, Heb, Ralphs } from '../../assets/images';

export const Context = createContext();

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(
        (state, action) => {
            return { ...state, ...action };
        },
        {
            selectedTab: '',
            mainNavIsOpen: false,
            productsNavIsOpen: false,
            modalSearchIsOpen: false,
            modalWhereToBuyIsOpen: false,
            storesModalProducts: {
                'original-recipe-refried-beans': [
                    {
                        img: Kroger,
                        link: 'https://www.kroger.com/p/isadora-original-refried-beans/0078162400805?fulfillment=PICKUP&searchType=default_search',
                        alt: 'Kroger',
                    },
                    {
                        img: Heb,
                        link: 'https://www.heb.com/product-detail/isadora-original-refried-beans-15-2-oz/1533976',
                        alt: 'Heb',
                    },
                    {
                        img: Ralphs,
                        link: 'https://www.ralphs.com/p/isadora-original-refried-beans/0078162400805?fulfillment=PICKUP&searchType=default_search',
                        alt: 'Ralphs',
                    },
                ],
                'mayocoba-refried-beans': [
                    {
                        img: Kroger,
                        link: 'https://www.kroger.com/p/isadora-mayocoba-peruano-refried-beans/0078162400844?fulfillment=PICKUP&searchType=default_search',
                        alt: 'Kroger',
                    },
                    {
                        img: Ralphs,
                        link: 'https://www.ralphs.com/p/isadora-mayocoba-peruano-refried-beans/0078162400844?fulfillment=PICKUP&searchType=default_search',
                        alt: 'Ralphs',
                    },
                ],
                'Black-Refried-beans': [
                    {
                        img: Kroger,
                        link: 'https://www.kroger.com/p/isadora-black-refried-beans/0078162400786?fulfillment=PICKUP&searchType=default_search',
                        alt: 'Kroger',
                    },
                    {
                        img: Ralphs,
                        link: 'https://www.ralphs.com/p/isadora-black-refried-beans/0078162400786?fulfillment=PICKUP&searchType=default_search',
                        alt: 'Ralphs',
                    },
                ],
                'Low-Fat-Refried-Beans': [
                    {
                        img: Heb,
                        link: 'https://www.heb.com/product-detail/isadora-low-fat-refried-beans-15-2-oz/1533975',
                        alt: 'Heb USA',
                    },
                ],
                'Traditional-Refried-Beans': [
                    {
                        img: Kroger,
                        link: 'https://www.kroger.com/p/isadora-traditional-refried-beans/0078162400787?fulfillment=PICKUP&searchType=default_search',
                        alt: 'Kroger',
                    },
                    {
                        img: Heb,
                        link: 'https://www.heb.com/product-detail/isadora-traditional-refried-beans-15-2-oz/1533978',
                        alt: 'Heb USA',
                    },
                    {
                        img: Ralphs,
                        link: 'https://www.ralphs.com/p/isadora-traditional-refried-beans/0078162400787?fulfillment=PICKUP&searchType=default_search',
                        alt: 'Ralphs',
                    },
                ],
                'Whole-Black-Beans': [],
                'Whole-Mayocoba-Beans': [],
                'Whole-Pinto-Beans': [],
            },
        }
    );
    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export default Store;
