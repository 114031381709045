import { Link } from 'react-router-dom';
import { MapProvisional } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faArrowTrendUp, faEgg } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';


const LocationsWhereToBuy = () => {
 return (
            <div id='wb-locations-box' className="container mt-5 pb-5">
                <div className="row d-flex justify-content-between pb-5 flex-row-reverse flex-md-row">
                    <div id='wb-map' className="col-md-7 col-lg-8 px-md-0">
                        <img src={MapProvisional} alt="Map" />
                    </div>
                    <div id='wb-locations' className="col-md-5 col-lg-4 d-flex flex-column p-md-0">
                            <div id='header-location-list'
                                className='p-2 w-100'>
                                <Link 
                                    to="/products"
                                    className='font-montserrat text-uppercase'>&#x3C; Back to products</Link>
                            </div>
                        <div className='bg-white'>
                            <div id='box-location-list'
                                className='w-100'>
                                    <ol className='mb-0'>
                                        <li className='location-item w-100 d-flex'>
                                            <div className='col-1 position-relative'>
                                                <span className='font-gotham-bold number-list'>1</span>
                                            </div>
                                            <div className='col-1 d-flex flex-column justify-content-between'>
                                                <div></div>
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <FontAwesomeIcon icon={faArrowTrendUp} className='arrow-trend-up d-block fa-md text-center mx-auto' />
                                                </div>
                                            </div>
                                            <div className='col-9 d-flex flex-column justify-content-between'>
                                                <div>
                                                    <h3 className='font-gotham-bold'>7 Eleven</h3>
                                                    <span className='font-gotham-book'>1320 E Oitorf St.</span>
                                                </div>
                                                <div>
                                                    <span className='d-block font-gotham-book'>0.9 miles away</span>
                                                    <span className='d-block font-gotham-book'>2 selected products found</span>
                                                </div>
                                            </div>
                                            <div className='col-1'>
                                                <a href="#" className='btn-list'>
                                                        <FontAwesomeIcon icon={faChevronRight} className='d-block fa-md text-center mx-auto' />
                                                </a>
                                            </div>
                                        </li>
                                    </ol>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 );
};

export default LocationsWhereToBuy;