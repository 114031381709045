import { useEffect, useReducer, useContext } from 'react';
import { Context } from '../../components/Store';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import ModalWhereToBuy from '../../components/ModalWhereToBuy';
import { Helmet } from 'react-helmet';
import { useFetch } from '../../utilities/hooks';
import {
    NavigationProducts,
    Preloader,
    RelatedRecipesFromRecipes,
} from '../../components';
import './styles.scss';

const Products = () => {
    const {
        state: { modalWhereToBuyIsOpen, storesModalProducts },
        dispatch: setModalWhereToBuyIsOpen,
    } = useContext(Context);

    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const { pathname } = useLocation();
    const { slug } = useParams();
    const navigate = useNavigate();
    const { data } = useFetch(`${BASE_URL}/api/products_en`);
    const [state, dispatch] = useReducer(
        (state, action) => {
            return { ...state, ...action };
        },
        {
            product: {},
            mouseOver: false,
            selectedTab: 'ingredients',
        }
    );

    const product = slug
        ? data?.products.reduce(
              (acc, el) => (el.slug === slug ? (acc = el) : acc),
              {}
          )
        : data?.product;

    const changeImageProduct = () => {
        dispatch({ mouseOver: !state.mouseOver });
    };
    useEffect(() => {
        if (data) {
            dispatch({ product });
            Object.keys(product).length === 0 && navigate('/products');
        }
    }, [pathname, data]);

    const openModal = () => {
        setModalWhereToBuyIsOpen({ modalWhereToBuyIsOpen: true });
    };

    return (
        <>
            {data ? (
                <section id='productsSection'>
                    <Helmet>
                        <meta charSet='utf-8' />
                        <title>Our Products | Isadora Beans</title>
                        <link rel='canonical' href={window.location.href} />
                    </Helmet>
                    <NavigationProducts products={data.products} />
                    <div className='container py-6'>
                        <div
                            className='row d-flex justify-content-center px-md-4'
                            id='main-product-row'
                        >
                            <div
                                id='col-text'
                                className='col-12 col-sm-12 col-md-7'
                            >
                                <div>
                                    <h4
                                        className='productTitle'
                                        dangerouslySetInnerHTML={{
                                            __html: state.product
                                                .formated_title,
                                        }}
                                    ></h4>
                                    <p className='productDescription font-gotham-bold'
                                        dangerouslySetInnerHTML={{__html: state.product.details}}></p>
                                    <div className='ingredients-nutrition-cont'>
                                        <div className='d-flex justify-content-center mb-4'>
                                            {[
                                                'ingredients',
                                                'nutrition facts',
                                            ].map((el, i) => (
                                                <button
                                                    key={i}
                                                    className={`selectors${
                                                        state.selectedTab === el
                                                            ? ' active'
                                                            : ''
                                                    }`}
                                                    onClick={dispatch.bind(
                                                        null,
                                                        {
                                                            selectedTab: el,
                                                        }
                                                    )}
                                                >
                                                    {el}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        {state.selectedTab === 'ingredients' ? (
                                            <div>
                                                <p className='productDescription font-gotham-bold'>
                                                    INGREDIENTS:{' '}
                                                    {state.product.ingredients}
                                                </p>
                                            </div>
                                        ) : (
                                            <div className='row'>
                                                <img
                                                    src={
                                                        BASE_URL +
                                                        '/storage/' +
                                                        state.product
                                                            .nutricional_details
                                                    }
                                                    className='nutritionalImg mx-auto'
                                                    alt={
                                                        state.product.title +
                                                        ' Nutritional Image'
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className='container'>
                                        <div className='au-deco-line mx-auto my-3'></div>
                                        <div className='row my-3'>
                                            <Link
                                                to={
                                                    '/recipes/' +
                                                    state.product.id
                                                }
                                                className='btn btn-primary custom-brown-color'
                                            >
                                                LETS COOK
                                            </Link>
                                        </div>
                                        {storesModalProducts[state.product.slug]
                                            ?.length > 0 && (
                                            <>
                                                <div className='row'>
                                                    <button
                                                        className='btn btn-primary custom-brown-color'
                                                        onClick={() =>
                                                            openModal()
                                                        }
                                                    >
                                                        BUY NOW
                                                    </button>
                                                </div>
                                                {modalWhereToBuyIsOpen && (
                                                    <ModalWhereToBuy
                                                        OpenClose
                                                        Position
                                                        productsSlug={
                                                            state.product.slug
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-5'>
                                <div>
                                    <img
                                        src={
                                            !state.mouseOver
                                                ? BASE_URL +
                                                  '/storage/' +
                                                  state.product.active_image
                                                : BASE_URL +
                                                  '/storage/' +
                                                  state.product.backside_imagen
                                        }
                                        alt={state.product?.title + 'Image'}
                                        className='productImage img-fluid'
                                        onMouseOver={changeImageProduct}
                                        onMouseLeave={changeImageProduct}
                                        id='frontProductImg'
                                    />
                                    <div className='row d-flex justify-content-center hoverInstructions'>
                                        Hover over the image to see the reverse
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <Preloader />
            )}
            <section id='related-recipes-sec' className='py-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='d-flex flex-column align-items-center'>
                            <h2 className='text-white text-center mb-5'>
                                <span className='font-bernierd text-lowercase'>
                                    Recipes Prepared With
                                </span>
                                <br></br>
                                <span className='font-kellog'>
                                    {state.product.title}
                                </span>
                            </h2>
                        </div>
                    </div>
                    <RelatedRecipesFromRecipes productId={state.product.id} />
                </div>
            </section>
        </>
    );
};

export default Products;
