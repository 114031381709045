import {
    BannerNews,
    Miniatura,
    Detalle,
} from '../../assets/images';
import { TypeOfBean, NewsGrid, FilterNews } from '../../components';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import React from 'react';
import { Helmet } from 'react-helmet';
import './styles.scss';

const News = () => {
    //const navigate = useNavigate();
    //const { pathname } = useLocation();
    const { id = 16 } = useParams();

  return (
    <>
        <section id='News' className='alx-back-seccion'>
            <Helmet>
                <meta charSet='utf-8' />
                <title>News | Isadora Beans</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='container'>
                <div className='row'>

                    <div className="col-xs-12 col-md-12 col-lg-9" id="alx-div-banner-home-noticias">
                        <div className="alx-banner">
                        <img
                            src={BannerNews}
                            alt="News Isadora"
                            className='alx-img-full'
                        />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-3">
                        <FilterNews />
                    </div>
                    
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="col-xs-12 col-md-12 col-lg-9">
                            <NewsGrid />
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-3">
                    </div>
                </div>
            </div>

        </section>


        

    </>
  );
}

export default News;
