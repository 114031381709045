import PropTypes from 'prop-types';
import { Navbar } from 'react-bootstrap';
import { useContext } from 'react';
import { Context } from '../Store';
import './style.scss';

const HamburgerMenu = ({ ariaControls }) => {
    const {
        state: { mainNavIsOpen, productsNavIsOpen },
        dispatch,
    } = useContext(Context);

    return (
        <Navbar.Toggle
            aria-controls={ariaControls}
            className={`ham-menu${
                (mainNavIsOpen && ariaControls === 'basic-navbar-nav') ||
                (productsNavIsOpen && ariaControls === 'products-navbar-nav')
                    ? ' ham-menu--open'
                    : ''
            } border-0 shadow-none`}
            onClick={dispatch.bind(
                null,
                ariaControls === 'basic-navbar-nav'
                    ? {
                          mainNavIsOpen: !mainNavIsOpen,
                      }
                    : {
                          productsNavIsOpen: !productsNavIsOpen,
                      }
            )}
        >
            {[...Array(4)].map((_, i) => (
                <span key={i}></span>
            ))}
        </Navbar.Toggle>
    );
};

HamburgerMenu.propTypes = {
    ariaControls: PropTypes.string.isRequired,
};

export default HamburgerMenu;
