import PropTypes from 'prop-types';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import { Context } from '../../Store';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { timeOut } from '../../../utilities/setTimeout';
import { HeaderSocialNav, Filter } from './';

const DesktopNav = memo(({ data }) => {
    const [firstLoad, setFirstLoad] = useState(null);
    const { pathname } = useLocation();
    const homePage = pathname === '/';
    const selectedTabRef = useRef(null);
    const {
        state: { selectedTab },
        dispatch,
    } = useContext(Context);
    const { isadoraLogo, list } = data;
    const nav = [...list];
    const getPosition = () => {
        return selectedTab?.offsetLeft + (selectedTab?.clientWidth - 100) / 2;
    };
    /*nav.splice(2, 0, isadoraLogo);*/
    const setSelectedTab = item =>
        pathname.slice(1).replace('/', '').includes(item.path)
            ? null /*selectedTabRef*/
            : null;

    useEffect(() => {
        const moveTrack = async () => {
            await timeOut(200);
            dispatch({
                selectedTab: selectedTabRef.current,
            });
            await timeOut(100);
            setFirstLoad(true);
        };
        moveTrack();
        window.addEventListener('resize', moveTrack);
        return () => window.removeEventListener('resize', moveTrack);
    }, [pathname]);
    return (
        <Navbar expand='lg' className='py-0'>
            <Container fluid className='flex-column p-0'>
                <HeaderSocialNav />
                {!homePage && (
                    <Nav className='w-100' id="alx-div-menu-h">
                        <Container>

                            
                           

                            <NavLink
                                to={isadoraLogo.path}
                                className='d-block header__navbar-link position-relative'
                                ref={setSelectedTab(isadoraLogo)} 
                                id='alx-logo-gral'
                                onClick={e =>
                                    dispatch({
                                        selectedTab:
                                            isadoraLogo.path === '/'
                                                ? null
                                                : e.target,
                                    })
                                }
                            >
                                
                                    <img
                                        src={isadoraLogo.src}
                                        alt={isadoraLogo.alt}
                                        className='header__logo header__logo--desktop' 

                                    />
                                
                            </NavLink>


                            <ul className='d-flex justify-content-around align-items-center mb-0 position-relative' id="alx-menu-gral">
                                {selectedTab && (
                                    <div className='header__trackbar-container position-absolute w-100'>
                                        <div
                                            className={`header__trackbar ${
                                                firstLoad &&
                                                'header__trackbar--show'
                                            } position-absolute`}
                                            style={{
                                                transform: `translateX(${getPosition()}px)`,
                                            }}
                                        >
                                            <div className='header__trackbar-box'></div>
                                        </div>
                                    </div>
                                )}

                                {nav.map((item, i) => (
                                

                                    <li
                                        key={i}
                                        className={`header__navbar-item${
                                            item.src
                                                ? ' header__navbar-item--logo'
                                                : ''
                                        }`}
                                    >
                                        <NavLink
                                            to={item.path}
                                            className='d-block header__navbar-link position-relative'
                                            ref={setSelectedTab(item)}
                                            onClick={e =>
                                                dispatch({
                                                    selectedTab:
                                                        item.path === '/'
                                                            ? null
                                                            : e.target,
                                                })
                                            }
                                        >
                                            {item.src ? (
                                                <img
                                                    src={item.src}
                                                    alt={item.alt}
                                                    className='header__logo header__logo--desktop'
                                                />
                                            ) : (
                                                item.text
                                            )}
                                        </NavLink>
                                    </li>

                                
                                

                                ))}
                            </ul>
                        </Container>
                    </Nav>
                )}
            </Container>
            <Filter />
        </Navbar>
    );
});

DesktopNav.propTypes = {
    data: PropTypes.object.isRequired,
};

export default DesktopNav;
