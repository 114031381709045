import PropTypes from 'prop-types';
import { memo } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { HeaderSocialNav } from './';
import { HamburgerMenu } from '../../';

const MobileNav = memo(({ data }) => {
    const { isadoraLogo, list: nav, mainNavIsOpen } = data;

    return (
        <Navbar expand='lg' expanded={mainNavIsOpen} className='pb-0'>
            <Container fluid className='overflow-hidden'>
                <Navbar.Brand>
                    <Link to='/'>
                        <img
                            src={isadoraLogo.src}
                            className='header__logo header__logo--mobile'
                            alt={isadoraLogo.alt}
                        />
                    </Link>
                </Navbar.Brand>
                <HamburgerMenu ariaControls='basic-navbar-nav' />
                <Navbar.Collapse
                    className='header__navbar-mobile'
                    id='basic-navbar-nav'
                >
                    <HeaderSocialNav />
                    <Nav>
                        {nav.map((item, i, arr) => (
                            <NavLink
                                key={i}
                                to={item.path}
                                className={`py-2 ${
                                    i !== arr.length - 1 && 'border-bottom'
                                } header__navbar-link`}
                            >
                                {item.text}
                            </NavLink>
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
});

MobileNav.propTypes = {
    data: PropTypes.object.isRequired,
};

export default MobileNav;
