import { Link } from 'react-router-dom';
import { Recipes1 } from '../../assets/images';
import { useEffect } from 'react';
import { useFetch } from '../../utilities/hooks';
import './styles.scss';

const RelatedRecipesFromRecipes = ({ productId, recipeId }) => {
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const { data } = useFetch(
        `${BASE_URL}/api/${
            recipeId ? 'get_related_recipes' : 'get_random_recipes'
        }/${productId}${recipeId ? '/' + recipeId : ''}`
    );

    useEffect(() => {
        if (data) {
            console.log(data);
        }
    }, [data]);

    return (
        <div className='row justify-content-center flex-column flex-md-row'>
            {data?.recipes ? (
                data?.recipes.length == 0 ? (
                    <>Sin resultados</>
                ) : (
                    <>
                        {data.recipes.map((recipe, i) => {
                            return (
                                <div
                                    className='col-md-4 col-lg-3 recipe-card
                                    font-gotham-bold related-recipes-grid
                                    px-3 mb-3'
                                    key={i}
                                >
                                    <Link
                                        to={'/recipe/' + recipe.slug}
                                        onClick={() => window.scrollTo(0, 0)}
                                    >
                                        <div className='mb-2'>
                                            <img
                                                src={
                                                    BASE_URL +
                                                    '/storage/recipes/' +
                                                    recipe.image
                                                }
                                                alt=''
                                                className='img-fluid'
                                            />
                                        </div>
                                        <h3 className='text-white capitalize text-center'>
                                            {recipe.title.toLowerCase()}
                                        </h3>
                                    </Link>
                                </div>
                            );
                        })}
                    </>
                )
            ) : (
                <>Cargando...</>
            )}
        </div>
    );
};

export default RelatedRecipesFromRecipes;
