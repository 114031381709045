export const typeOfBeansLinks = [
    {
        id: 16,
        text: 'Original Refried Beans',
    },
    {
        id: 14,
        text: 'Black Refried Beans',
    },
    {
        id: 15,
        text: 'Mayocoba Refried Beans',
    },
    {
        id: 13,
        text: 'Fat Free Refried Beans',
    },
    {
        id: 12,
        text: 'Traditional Refried Beans',
    },
    {
        id: 11,
        text: 'Whole Black Beans',
    },
    {
        id: 10,
        text: 'Whole Mayocoba Beans',
    },
    {
        id: 9,
        text: 'Whole Pinto Beans',
    },
];
