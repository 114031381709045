import { useContext } from 'react';
import { Context } from '../Store';
import { NavLink, useParams } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { HamburgerMenu } from '../';
import './styles.scss';

const NavigationProducts = props => {
    const {
        state: { productsNavIsOpen },
    } = useContext(Context);
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const { slug } = useParams();
    return (
        <>
            <Navbar
                expand='md'
                expanded={productsNavIsOpen}
                className='products-navbar justify-content-center'
            >
                <Container fluid='lg' className='products-navbar__container'>
                    <Navbar.Brand className='mx-auto ps-4 text-uppercase text-white fw-bold d-md-none text-center'>
                        Products
                    </Navbar.Brand>
                    <HamburgerMenu ariaControls='products-navbar-nav' />
                    <Navbar.Collapse
                        id='products-navbar-nav'
                        className='products-navbar__nav'
                    >
                        <Nav className='d-flex justify-content-around'>
                            {props.products.map((item, index) => {
                                return (
                                    <div
                                        className='px-md-2 products-navbar__product-container'
                                        key={index}
                                    >
                                        <NavLink
                                            to={`/products/${item.slug}`}
                                            className={`products-navbar__link d-flex flex-column justify-content-between${
                                                index === 0 && !slug
                                                    ? ' active'
                                                    : ''
                                            }`}
                                        >
                                            <img
                                                src={
                                                    BASE_URL +
                                                    '/storage/' +
                                                    item.thumbnail_image
                                                }
                                                alt={item.title + 'Image'}
                                                className='d-none d-md-block img-fluid'
                                            />
                                            <h5
                                                className='products-navbar__title text-center mb-0 fw-bold p-2 p-md-1 text-uppercase'
                                                id={'productNavTitle' + index}
                                            >
                                                {item.title}
                                            </h5>
                                        </NavLink>
                                    </div>
                                );
                            })}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default NavigationProducts;
