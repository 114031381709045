import {
    KrogerWhite,
    PlateOfBeans,
    FraseGuisos,
    GuisoBanner,
    favicon,
    bannerIsadoraMob, 
    bannerIsadoraFHD
} from '../../assets/images';
import { LogoVerdeValleMain } from '../../assets/images';
import { AsHomemade1 } from '../../assets/images';
import { AsHomemade2 } from '../../assets/images';
import { AsHomemade3 } from '../../assets/images';
import { Recipes1 } from '../../assets/images';
import { Recipes2 } from '../../assets/images';
import { Recipes3 } from '../../assets/images';
import { Recipes4 } from '../../assets/images';
import { Recipes5 } from '../../assets/images';
import { Recipes6 } from '../../assets/images';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import { Helmet } from 'react-helmet';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { navigateTo } from '../../utilities/hooks';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './styles.scss';
import 'animate.css/animate.min.css';

const Home = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>Isadora Beans | Mexico's #1 beans in a pouch</title>
                <link rel='canonical' href={window.location.href} />
                <link rel='icon' href={favicon} />
            </Helmet>
            <section id='homeHeroSection'>
                <div className='container'>
                    <div className='row d-none d-lg-block'>
                        <div>
                            <Link to='/'>
                                <img
                                    src={LogoVerdeValleMain}
                                    alt='Isadora Logo'
                                    className='d-block mx-auto mb-4'
                                    id='logo-home'
                                />
                            </Link>
                        </div>
                        <div>
                            <nav>
                                <ul className='d-flex text-uppercase text-white list-unstyled justify-content-center font-montserrat font-bold'>
                                    <li className='px-4'>
                                        <Link to='/about-us'>About Us</Link>
                                    </li>
                                    <li className='px-4'>
                                        <Link to='/products'>Products</Link>
                                    </li>
                                    <li className='px-4'>
                                        <Link to='/beans-a-superfood'>
                                            Beans, as a super food
                                        </Link>
                                    </li>
                                    <li className='px-4'>
                                        <Link to='/recipes'>Recipes</Link>
                                    </li>
                                    <li className='px-4'>
                                        <Link to='/news'>News</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <h1 className='mt-5 text-center font-oswald text-white mb-0'>
                                <span>
                                    Mexico’s <span className='sign'>#</span>
                                </span>
                                <span className='title-number'>1</span>
                                <br></br>
                            </h1>

                            <span className='font-source-sans-italic subtitle text-white'>
                                Beans in a pouch
                            </span>

                            <div id='beans-plate'></div>
                        </div>
                    </div>
                </div>
            </section>
            <div id='homeBox2'>
                <div className='container'>
                    <section
                        id='home-sec2'
                        className='row justify-content-center mb-5 mb-lg-0'
                    >
                        <AnimationOnScroll
                            duration={2}
                            animateOnce
                            animateIn='animate__animated animate__backInLeft'
                        >
                            <div
                                id='home-1min-img'
                                className='floating-element d-none d-md-block'
                            ></div>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            duration={2}
                            animateOnce
                            animateIn='animate__animated animate__backInRight'
                        >
                            <div
                                id='home-pouch-img'
                                className='floating-element'
                            ></div>
                        </AnimationOnScroll>
                        <div
                            id='home-beans-img'
                            className='floating-element'
                        ></div>
                        <div
                            id='home-bean1-img'
                            className='floating-element d-none d-lg-block'
                        ></div>
                        <div
                            id='home-bean2-img'
                            className='floating-element'
                        ></div>
                        <div
                            id='home-bean3-img'
                            className='floating-element'
                        ></div>
                        <AnimationOnScroll
                            duration={2}
                            animateOnce
                            animateIn='animate__animated animate__backInRight'
                        >
                            <div
                                id='home-bean4-img'
                                className='floating-element d-none d-lg-block'
                            ></div>
                            <div
                                id='home-bean5-img'
                                className='floating-element d-none d-lg-block'
                            ></div>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            duration={2}
                            animateOnce
                            animateIn='animate__animated animate__backInLeft'
                        >
                            <div
                                id='home-leaf1-img'
                                className='floating-element'
                            ></div>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            duration={2}
                            animateOnce
                            animateIn='animate__animated animate__backInRight'
                        >
                            <div
                                id='home-leaf2-img'
                                className='floating-element'
                            ></div>
                        </AnimationOnScroll>
                        <div className='col-md-8 col-lg-5 d-flex flex-column align-items-center'>
                            <h2 className='font-bernierd text-orangeIs text-lowercase text-center'>
                                As Good<br></br> <span>As Homemade</span>
                            </h2>
                            <p className='text-center text-pineIs font-gotham-book'>
                                Isadora Beans are the tastiest, freshly made and
                                vacuum-packed without preservatives. Try them on
                                their own or add your personal touch.
                            </p>
                            <Link
                                className='orange-btn custom-brown-color'
                                to='/products'
                                onClick={e =>
                                    navigateTo(
                                        e,
                                        pathname,
                                        '/products',
                                        navigate
                                    )
                                }
                            >
                                Check out our products
                            </Link>
                        </div>
                    </section>
                    <div className='row justify-content-center flex-row my-5 d-none d-lg-flex'>
                        <div className='col-lg-3'>
                            <img
                                src={AsHomemade1}
                                alt='Imagen de la receta'
                                className='img-fluid'
                            />
                        </div>
                        <div className='col-lg-3'>
                            <img
                                src={AsHomemade2}
                                alt='Imagen de la receta'
                                className='img-fluid'
                            />
                        </div>
                        <div className='col-lg-3'>
                            <img
                                src={AsHomemade3}
                                alt='Imagen de la receta'
                                className='img-fluid'
                            />
                        </div>
                    </div>
                    <OwlCarousel
                        className='owl-carousel-home d-lg-none'
                        items={2}
                        margin={15}
                        loop
                        autoplay={true}
                        autoplayTimeout={2000}
                        autoplayHoverPause={true}
                    >
                        <div className='item'>
                            <img src={AsHomemade1} alt='Imagen de la receta' />
                        </div>
                        <div className='item'>
                            <img src={AsHomemade2} alt='Imagen de la receta' />
                        </div>
                        <div className='item'>
                            <img src={AsHomemade3} alt='Imagen de la receta' />
                        </div>
                    </OwlCarousel>
                    <section
                        id='home-sec3'
                        className='row justify-content-center mb-5 mb-lg-0'
                    >
                        <AnimationOnScroll
                            duration={2}
                            animateOnce
                            animateIn='animate__animated animate__backInLeft'
                        >
                            <div
                                id='home-beans-nachos-img'
                                className='floating-element'
                            ></div>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            duration={2}
                            animateOnce
                            animateIn='animate__animated animate__backInRight'
                        >
                            <div
                                id='home-leaf3-img'
                                className='floating-element'
                            ></div>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            duration={2}
                            animateOnce
                            animateIn='animate__animated animate__backInRight'
                        >
                            <div
                                id='home-bean6-img'
                                className='floating-element'
                            ></div>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            duration={2}
                            animateOnce
                            animateIn='animate__animated animate__backInRight'
                        >
                            <div
                                id='home-bean7-img'
                                className='floating-element'
                            ></div>
                        </AnimationOnScroll>
                        <div className='col-md-8 col-lg-4 d-flex flex-column align-items-center'>
                            <h2 className='font-kellog text-orangeIs text-center'>
                                Make incredible<br></br> <span>recipes</span>
                            </h2>
                            <p className='text-center text-pineIs font-gotham-book mt-3'>
                                Isadora Beans are the tastiest, freshly made and
                                vacuum-packed without preservatives. Try them on
                                their own or add your personal touch.
                            </p>
                            <Link
                                className='orange-btn custom-brown-color'
                                to='/recipes'
                                onClick={e =>
                                    navigateTo(
                                        e,
                                        pathname,
                                        '/recipes',
                                        navigate
                                    )
                                }
                            >
                                Get Started
                            </Link>
                        </div>
                    </section>

                    <div className='row justify-content-between flex-row my-5 d-none d-lg-flex'>
                        <div className='col-lg-2'>
                            <img
                                src={Recipes1}
                                alt='Imagen de la receta'
                                className='img-fluid'
                            />
                        </div>
                        <div className='col-lg-2'>
                            <img
                                src={Recipes2}
                                alt='Imagen de la receta'
                                className='img-fluid'
                            />
                        </div>
                        <div className='col-lg-2'>
                            <img
                                src={Recipes3}
                                alt='Imagen de la receta'
                                className='img-fluid'
                            />
                        </div>
                        <div className='col-lg-2'>
                            <img
                                src={Recipes4}
                                alt='Imagen de la receta'
                                className='img-fluid'
                            />
                        </div>
                        <div className='col-lg-2'>
                            <img
                                src={Recipes5}
                                alt='Imagen de la receta'
                                className='img-fluid'
                            />
                        </div>
                        <div className='col-lg-2'>
                            <img
                                src={Recipes6}
                                alt='Imagen de la receta'
                                className='img-fluid'
                            />
                        </div>
                    </div>
                    <OwlCarousel
                        className='owl-carousel-home d-lg-none'
                        margin={15}
                        items={3}
                        loop
                        autoplay={true}
                        autoplayTimeout={2000}
                        autoplayHoverPause={true}
                    >
                        <div className='item'>
                            <img src={Recipes1} alt='Imagen de la receta' />
                        </div>
                        <div className='item'>
                            <img src={Recipes2} alt='Imagen de la receta' />
                        </div>
                        <div className='item'>
                            <img src={Recipes3} alt='Imagen de la receta' />
                        </div>
                        <div className='item'>
                            <img src={Recipes4} alt='Imagen de la receta' />
                        </div>
                        <div className='item'>
                            <img src={Recipes5} alt='Imagen de la receta' />
                        </div>
                        <div className='item'>
                            <img src={Recipes6} alt='Imagen de la receta' />
                        </div>
                    </OwlCarousel>

                    <section id='home-sec4'>
                        <div className='row justify-content-center'>
                            <div
                                id='home-cilantro1-img'
                                className='floating-element'
                            ></div>
                            <div
                                id='home-cilantro2-img'
                                className='floating-element'
                            ></div>
                            <div className='col-12  my-5 d-flex flex-column align-items-center'>
                                <h2 className='text-center font-bernierd text-lowercase text text-darker-brownIs'>
                                    Beans<br></br> <span>As a Superfood</span>
                                </h2>
                                <Link
                                    className='orange-btn mt-1 custom-brown-color'
                                    to='/beans-a-superfood'
                                    onClick={e =>
                                        navigateTo(
                                            e,
                                            pathname,
                                            '/beans-a-superfood',
                                            navigate
                                        )
                                    }
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-md-3'>
                                <AnimationOnScroll
                                    duration={2}
                                    animateOnce
                                    animateIn='animate__animated animate__backInLeft'
                                >
                                    <div className='row justify-content-md-end judtify-content-center'>
                                        <div className='container-plate-text text-darker-brownIs text-center text-md-start'>
                                            <h3 className='font-bernierd text-lowercase'>
                                                A cup of<br></br>{' '}
                                                <span className='text-orangeIs'>
                                                    Beans
                                                </span>
                                                <br></br> has double
                                                protein of an egg.
                                            </h3>
                                            <div className='home-deco-line mt-2'></div>
                                            <p className='font-gotham-bold'>
                                                <span className='home-circle'></span>
                                                With practically<br></br> no fat
                                                at all.
                                            </p>
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                            <div className='col-md-5'>
                                <AnimationOnScroll
                                    duration={2}
                                    animateOnce
                                    animateIn='animate__animated animate__backInUp'
                                >
                                    <img
                                        src={PlateOfBeans}
                                        alt='Beans in a bowl'
                                        className='img-fluid plate-beans-home mx-auto d-block'
                                    />
                                </AnimationOnScroll>
                            </div>
                            <div className='col-md-3 box-right text-center text-md-end mt-4 mt-md-0'>
                                <AnimationOnScroll
                                    duration={2}
                                    animateOnce
                                    animateIn='animate__animated animate__backInRight'
                                >
                                    <div className='row'>
                                        <div className='container-plate-text text-darker-brownIs'>
                                            <h3 className='font-bernierd text-lowercase'>
                                                <span className='text-orangeIs'>
                                                    Beans
                                                </span>
                                                <br></br> are not only delicius,
                                                but also incredibly nutritious.
                                            </h3>
                                            <div className='home-deco-line mt-2'></div>
                                            <p className='font-gotham-bold'>
                                                <span className='home-circle'></span>
                                                Discover how beans help your
                                                body
                                                <br></br> stay healthy.
                                            </p>
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                        <div className='row justify-content-center py-5'>
                            <div className='col-lg-6 text-center box-bottom text-darker-brownIs'>
                                <h3 className='font-bernierd text-lowercase'>
                                    Congrats or comments?
                                </h3>
                                <p className='font-gotham-book'>
                                    We want to hear from you! Contact us and let
                                    us know your thoughts about our products and
                                    recipes.
                                </p>
                                <Link
                                    className='orange-btn custom-brown-color'
                                    to='/contact'
                                    onClick={e =>
                                        navigateTo(
                                            e,
                                            pathname,
                                            '/contact',
                                            navigate
                                        )
                                    }
                                >
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </section>
                </div>
            </div>


            <div id="alx-noticia-home-footer-arroz">
                <a id="alx-noticia-home-footer-arroz-btn" href="https://www.isadoramexicanfood.com/" target="_blank">BUY NOW</a>
            </div>








            {/*<section id='home-sec5' onClick={ () => window.open("https://www.isadoramexicanfood.com/", '_blank').focus() }>






                <div className='container py-3'>
                    <div className='row align-items-center flex-column-reverse flex-md-row'>
                        <div className='col-md-6'>
                            <div id='new-badget'>
                                <span>NEW</span>
                            </div>
                            <img
                                src={FraseGuisos}
                                alt='Mexico is just 2 minutes away'
                                className='img-fluid'
                                id="mex-is-away"
                            />
                            <div className='text-center'>
                                
                                <p className='d-inline-block font-alternate text-white text-uppercase mb-0'>
                                    Soon available at
                                </p>
                                <img
                                    src={KrogerWhite}
                                    alt='Kroger logo'
                                    className='d-inline-block'
                                    id='logo-kroger'
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <img
                                src={GuisoBanner}
                                id='banner-guiso-img'
                                alt='Guiso Bowl'
                                className='img-fluid'
                            />
                        </div>
                    </div>
                </div>
                <div id='gradient-footer'></div>
            </section>*/}
        </>
    );
};

export default Home;
