import {
    Pouch,
    PouchBack,
    CookingPot,
    Microwave,
    IconBPAFree,
    IconFreshFlavor,
    IconGlutenFree,
    IconHighFiber,
    IconMicrowave,
    IconPlantBased,
    IconPreservativesFree,
} from '../../assets/images';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useReducer } from 'react';
import { Helmet } from 'react-helmet';
import './styles.scss';
import 'animate.css/animate.min.css';

const AboutUs = () => {
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const changeImageProduct = () => {
        dispatch({ mouseOver: !state.mouseOver });
    };
    const [state, dispatch] = useReducer(
        (state, action) => {
            return { ...state, ...action };
        },
        {
            product: {},
            mouseOver: false,
            selectedTab: 'ingredients',
        }
    );
    return (
        <>
            <section id='aboutUsSection'>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>About Us | Isadora Beans</title>
                    <link rel='canonical' href={window.location.href} />
                </Helmet>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2
                                className='text-center text-orangeIs font-kellog au-title'
                                id='why-we-love-isadora'
                            >
                                Why we love <br></br>
                                <span className='text-lowercase text-center font-bernierd'>
                                    Isadora
                                </span>
                            </h2>
                            <div className='au-intro-box mx-auto'>
                                <p
                                    className={`text-center text-brownIs au-intro font-gotham-bold mb-0`}
                                >
                                    Because it preserves,{' '}
                                    <br className='d-none d-md-block d-lg-none'></br>
                                    <span className='font-kellog'>
                                        the original taste of our beans.
                                    </span>
                                </p>
                                <p className='text-center text-brownIs au-intro font-gotham-bold'>
                                    In 2005 Verde Valle created Isadora.{' '}
                                    <span className='text-uppercase'>
                                        The best ready to eat beans
                                    </span>{' '}
                                    <span className='font-kellog'>
                                        with no added preservatives
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className='d-block d-lg-none'>
                            <img
                                src={!state.mouseOver ? Pouch : PouchBack}
                                alt='Beans in a Pouch'
                                className='img-fluid mx-auto d-block au-center-image mb-3'
                            />
                            <div className='au-icon-special d-flex flex-column flex-md-row justify-content-center align-items-center mx-auto mt-5 mt-md-0  d-md-none'>
                                <img
                                    src={IconMicrowave}
                                    className='img-fluid mx-auto d-block mr-2'
                                    alt='Preservatives free'
                                />
                                <h3 className='text-center text-brownIs au-icon-t1 font-gotham-bold'>
                                    Preservatives<br></br>
                                    <span className='font-bernierd'>free</span>
                                </h3>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='row justify-content-center justify-content-md-between justify-content-lg-end align-items-center'>
                                <div className='au-icon d-block col-6 col-md-12'>
                                    <AnimationOnScroll
                                        duration={2}
                                        animateOnce
                                        animateIn='animate__animated animate__backInLeft'
                                    >
                                        <img
                                            src={IconPreservativesFree}
                                            className='img-fluid mx-auto d-block'
                                            alt='Preservatives free'
                                        />
                                        <h3 className='text-center text-brownIs au-icon-t1 font-gotham-bold'>
                                            Preservatives<br></br>
                                            <span className='font-bernierd'>
                                                free
                                            </span>
                                        </h3>
                                    </AnimationOnScroll>
                                </div>
                                <div className='au-icon d-block col-6 col-md-12'>
                                    <AnimationOnScroll
                                        duration={2}
                                        animateOnce
                                        animateIn='animate__animated animate__backInLeft'
                                    >
                                        <img
                                            src={IconGlutenFree}
                                            className='img-fluid mx-auto d-block'
                                            alt='Preservatives free'
                                        />
                                        <h3 className='text-center text-brownIs au-icon-t1 font-gotham-bold'>
                                            Gluten<br></br>
                                            <span className='font-bernierd'>
                                                free
                                            </span>
                                        </h3>
                                    </AnimationOnScroll>
                                </div>
                                <div className='au-icon d-block col-6 col-md-12 pb-3' id='icon-bpa-free'>
                                    <AnimationOnScroll
                                        duration={2}
                                        animateOnce
                                        animateIn='animate__animated animate__backInLeft'
                                    >
                                        <img
                                            src={IconBPAFree}
                                            className='img-fluid mx-auto d-block'
                                            alt='Preservatives free'
                                        />
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 d-none d-lg-block'>
                            <img
                                src={!state.mouseOver ? Pouch : PouchBack}
                                alt='Beans in a pouch, Original recipe from Mexico'
                                className='img-fluid mx-auto d-block au-center-image mb-3'
                                onMouseOver={changeImageProduct}
                                onMouseLeave={changeImageProduct}
                            />

                            <div className='au-icon-special d-flex flex-column flex-md-row justify-content-center align-items-center mx-auto' id='preservatives-free-mb'>
                                <AnimationOnScroll
                                    duration={2}
                                    animateOnce
                                    animateIn='animate__animated animate__backInUp'
                                    className='d-flex align-items-center'
                                >
                                    <img
                                        src={IconMicrowave}
                                        className='img-fluid mx-auto d-block mr-2'
                                        alt='Preservatives free'
                                    />
                                    <h3 className='text-center text-brownIs au-icon-t1 font-gotham-bold pb-1'>
                                        <span className='font-bernierd'>
                                            Ready to eat    
                                        </span><br></br>
                                        after just 1 minute
                                        
                                        
                                    </h3>
                                </AnimationOnScroll>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='row justify-content-center justify-content-md-between justify-content-lg-start align-items-center align-items-md-start align-items-lg-center'>
                                <div className='au-icon d-block col-6 col-md-12'>
                                    <AnimationOnScroll
                                        duration={2}
                                        animateOnce
                                        animateIn='animate__animated animate__backInRight'
                                    >
                                        <img
                                            src={IconHighFiber}
                                            className='img-fluid mx-auto d-block'
                                            alt='High fiber'
                                        />
                                        <h3 className='text-center text-brownIs au-icon-t1 font-gotham-bold'>
                                            High<br></br>
                                            <span className='font-bernierd'>
                                                fiber
                                            </span>
                                        </h3>
                                    </AnimationOnScroll>
                                </div>
                                <div className='au-icon d-block pb-3 col-6 col-md-12'>
                                    <AnimationOnScroll
                                        duration={2}
                                        animateOnce
                                        animateIn='animate__animated animate__backInRight'
                                    >
                                        <img
                                            src={IconPlantBased}
                                            className='img-fluid mx-auto d-block my-1dot25'
                                            alt='Plant based Protein'
                                        />
                                    </AnimationOnScroll>
                                </div>
                                <div className='au-icon d-block col-6 col-md-12' id='free-pba-mobile'>
                                    <AnimationOnScroll
                                        duration={2}
                                        animateOnce
                                        animateIn='animate__animated animate__backInRight'
                                    >
                                        <img
                                            src={IconBPAFree}
                                            className='img-fluid mx-auto d-block'
                                            alt='SUPERIOR FRESH FLAVOR'
                                        />
                                    </AnimationOnScroll>
                                </div>
                                <div className='au-icon d-block col-6 col-md-12'>
                                    <AnimationOnScroll
                                        duration={2}
                                        animateOnce
                                        animateIn='animate__animated animate__backInRight'
                                    >
                                        <img
                                            src={IconFreshFlavor}
                                            className='img-fluid mx-auto d-block my-1dot25'
                                            alt='SUPERIOR FRESH FLAVOR'
                                        />
                                        <h3 className='text-center text-brownIs au-icon-t2 font-gotham-bold'>
                                            Superior Fresh<br></br> Flavor vs
                                            <br></br>
                                            <span className='font-bernierd'>
                                                can flavor
                                            </span>
                                        </h3>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                        <div className='au-preparation-box d-flex flex-column justify-content-center mt-lg-5'>
                            <div className='au-deco-line mx-auto my-5'></div>
                            <h2 className='text-center text-orangeIs font-bernierd'>
                                preparation <br></br>
                                <span className='text-brownIs'>direction</span>
                            </h2>

                            <div className='row text-brownIs justify-content-center'>
                                <div className='col-lg-6 d-flex flex-column flex-md-row au-preparation-box au-preparation-box1 pb-5 pb-md-0'>
                                    <div className='font-gotham-bold'>
                                        <h3 className='text-uppercase'>
                                            Microwave
                                        </h3>
                                        <div className='au-deco-line'></div>
                                        <ol>
                                            <li>Tear open</li>
                                            <li>
                                                Heat on high for 1 min* and
                                                serve
                                            </li>
                                        </ol>
                                    </div>
                                    <div className='d-flex align-items-center au-img-box'>
                                        <img
                                            src={Microwave}
                                            alt='Microwave preparation'
                                            className='au-img-1 mx-auto'
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-6 d-flex flex-column flex-md-row au-preparation-box au-preparation-box2 justify-content-lg-end'>
                                    <div className='font-gotham-bold'>
                                        <h3 className='text-uppercase'>
                                            Stove
                                        </h3>
                                        <div className='au-deco-line'></div>
                                        <ol>
                                            <li>Open pouch</li>
                                            <li>Empty contents in a pan</li>
                                            <li>Heat until hot and serve</li>
                                        </ol>
                                    </div>
                                    <div className='au-img-box d-flex justify-content-center align-items-center'>
                                        <img
                                            src={CookingPot}
                                            alt='Stove preparation'
                                            className='au-img-2 mx-auto'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='au-instructions-box font-gotham-bold text-brownIs'>
                                <p className='text-center'>
                                    Caution: Pouch will be hot, be careful when
                                    opening and handling the pouch.
                                    <br></br>*The time to obtain the recommended
                                    temperature depends on the appliance.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutUs;
