import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

const SearchBar = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState('');

    const makeSearch = e => {
        e.preventDefault();
        navigate('/results/' + value);
    };

    return (
        <div className='col-lg-3 col-md-6 mt-3 mb-5'>
            <form id='search-form' action='' onSubmit={e => makeSearch(e)}>
                <input
                    id='search-field'
                    type='text'
                    placeholder='Search our recipes'
                    onChange={e => setValue(e.target.value)}
                />
                <button id='search-button' className='circle-btn-orange'>
                    <FontAwesomeIcon icon={faSearch} className='fa-lg' />
                </button>
            </form>
        </div>
    );
};

export default SearchBar;
