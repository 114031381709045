import { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Context } from '../../components/Store';
import {
    Home,
    AboutUs,
    Contact,
    NotFound,
    WhereBuy,
    Recipes,
    Products,
    BeansASuperfood,
    SingleRecipe,
    Results,
    PrivacyPolicy,
    FAQS,
    News,
    SingleNews,
    ResultsNews,
} from '../';
import { Header, Footer, ModalSearch } from '../../components';

const Router = () => {
    const {
        state: { modalSearchIsOpen },
    } = useContext(Context);
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about-us' element={<AboutUs />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/recipes' element={<Recipes />} />
                <Route path='/recipes/:id' element={<Recipes />} />
                <Route path='/recipe/:slug' element={<SingleRecipe />} />
                <Route path='/products' element={<Products />} />
                <Route path='/products/:slug' element={<Products />} />
                <Route path='/results/:search' element={<Results />} />
                <Route path='/where-to-buy' element={<WhereBuy />} />
                <Route
                    path='/beans-a-superfood'
                    element={<BeansASuperfood />}
                />
                <Route path='*' element={<NotFound />} />

                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/faqs' element={<FAQS />} />
                <Route path='/news' element={<News />} />
                <Route path='/news/:slug' element={<SingleNews />} />
                <Route path='/news/results/:category/:date/:search' element={<ResultsNews />} />
            </Routes>
            <Footer />
            {modalSearchIsOpen && <ModalSearch />}
        </BrowserRouter>
    );
};

export default Router;
