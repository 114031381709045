import { memo, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from '../../Store';
import { navigation } from '../../../utilities/navigation';
import { useMediaQuery } from '../../../utilities/hooks';
import { SocialMedia } from '../..';

const HeaderSocialNav = memo(() => {
    const { dispatch } = useContext(Context);
    const { language, search, cart } = navigation;
    const breakpointLg = useMediaQuery('lg');

    return (
        <ul className='d-flex flex-wrap align-items-center py-3 mb-0 header__navbar-social w-100 position-relative'>
            <a href={language.link} className='d-flex align-items-center'>
                <span>{language.text}</span>
                <img
                    src={language.src}
                    alt={language.alt}
                    className='circle-btn circle-btn--bg-none ms-2'
                />
            </a>
            <li>
                <SocialMedia />
            </li>
            <li className='circle-btn ms-auto ms-lg-5 header__navbar-search'>
                <button
                    aria-label={search.label}
                    onClick={() => dispatch({ modalSearchIsOpen: true })}
                >
                    <FontAwesomeIcon icon={search.icon} className='fa-lg' />
                </button>
            </li>
            {breakpointLg && (
                <li
                    className='ms-lg-auto'
                    onClick={() =>
                        dispatch({
                            selectedTab: null,
                        })
                    }
                >
                    <Link to={cart.path} className='d-flex align-items-center'>
                        <img
                            src={cart.icon}
                            alt={cart.alt}
                            className='circle-btn circle-btn--bg-none me-2'
                        />
                        <span>{cart.text}</span>
                    </Link>
                </li>
            )}
        </ul>
    );
});

export default HeaderSocialNav;
