import { Link } from 'react-router-dom';
import { typeOfBeansLinks } from './navigation';
import './styles.scss';

const TypeOfBean = ({ productId }) => {
    const getActive = id => {
        if (Number(productId) === id) return 'active';
    };
    return (
        <div id='type-of-bean-sec'>
            <h2 className='text-orangeIs font-gotham-bold text-uppercase'>
                Type of bean
            </h2>
            <ul className='font-gotham-bold text-capitalize'>
                {typeOfBeansLinks.map(link => (
                    <li key={link.id}>
                        <Link
                            to={`/recipes/${link.id}`}
                            className={getActive(link.id)}
                        >
                            {link.text}
                        </Link>
                        <div className='arrow-tab'></div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TypeOfBean;
