import { useContext } from 'react';
import { Context } from '../Store';
import { Walmart, Amazon, Costco, Kroger, Target } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

const ModalWhereToBuy = ({
    OpenClose,
    Position,
    clickHandler,
    productsSlug,
}) => {
    const {
        state: { storesModalProducts },
        dispatch,
    } = useContext(Context);
    const closeModal = () => {
        clickHandler && clickHandler();
        dispatch({ modalWhereToBuyIsOpen: false });
    };
    return (
        <>
            <div
                id='whereToBuyModal'
                className={`${Position ? 'position-center' : ''}`}
            >
                <div
                    id='containerWhereToBuyModal'
                    className='mx-auto d-flex flex-column'
                >
                    {OpenClose && (
                        <button className='close-btn-modal'>
                            <FontAwesomeIcon
                                icon={faXmark}
                                className='fa-xl'
                                onClick={closeModal}
                            />
                        </button>
                    )}
                    <div className='container'>
                        <div className='row mt-5 mb-5'>
                            <h3 className='text-center font-gotham-bold'>
                                Select your favorite retailer to purchase
                                products online.
                            </h3>
                        </div>
                    </div>
                    <div className='container px-0'>
                        {productsSlug ? (
                            <div className='row'>
                                <div className='d-flex justify-content-center align-items-center flex-wrap store-container'>
                                    {storesModalProducts[productsSlug].map(
                                        (store, i) => (
                                            <a
                                                key={i}
                                                href={store.link}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                className='d-block m-0 store-container__store'
                                            >
                                                <img
                                                    src={store.img}
                                                    alt={store.alt}
                                                    className='img-fluid store-container__img'
                                                />
                                            </a>
                                        )
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className='row d-flex justify-content-center mt-5 mt-md-0 mb-md-5 align-items-center'>
                                    <div className='col-md-4 mb-5 mb-md-0'>
                                        <a
                                            href='https://www.kroger.com/search?query=isadora&searchType=default_search'
                                            target='_blank'
                                        >
                                            <img
                                                src={Kroger}
                                                alt='Kroger Logo'
                                                className='img-fluid-custom'
                                            />
                                        </a>
                                    </div>
                                    <div className='col-md-4 mb-5 mb-md-0'>
                                        <a
                                            href='https://www.target.com/s?searchTerm=isadora'
                                            target='_blank'
                                        >
                                            <img
                                                src={Target}
                                                alt='Target Logo'
                                                className='img-fluid-custom'
                                            />
                                        </a>
                                    </div>
                                    <div className='col-md-4 mb-5 mb-md-0'>
                                        <a
                                            href='https://www.walmart.com/search?q=isadora'
                                            target='_blank'
                                        >
                                            <img
                                                src={Walmart}
                                                alt='Walmart Logo'
                                                className='img-fluid-custom'
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className='row d-flex justify-content-center mt-md-5 mb-5 align-items-center'>
                                    <div className='col-md-4 mb-5 mb-md-0'>
                                        <a
                                            href='https://www.amazon.com/s?k=isadora&crid=32CJSJVT8G4IG&sprefix=amazon%2Caps%2C215&ref=nb_sb_noss_1'
                                            target='_blank'
                                        >
                                            <img
                                                src={Amazon}
                                                alt='Amazon Logo'
                                                className='img-fluid-custom'
                                            />
                                        </a>
                                    </div>
                                    <div className='col-md-4 mb-5 mb-md-0'>
                                        <a
                                            href='https://www.costco.com/CatalogSearch?dept=All&keyword=isadora'
                                            target='_blank'
                                        >
                                            <img
                                                src={Costco}
                                                alt='Costco Logo'
                                                className='img-fluid-custom'
                                            />
                                        </a>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

ModalWhereToBuy.defaultProps = {
    OpenClose: null,
    Position: null,
    clickHandler: null,
    productsSlug: null,
};

export default ModalWhereToBuy;
