import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const rootElement = document.getElementById('root');

'scrollRestoration' in window.history &&
    (window.history.scrollRestoration = 'manual');
const root = ReactDOM.createRoot(rootElement);
root.render(<App />);
