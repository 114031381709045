import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './styles.scss';

class FAQS extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>FAQS | Isadora Beans</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <section id='faqs-bg'>
                    <form className="container brown p-5" onSubmit={this.handleSubmit} id="mainFormContact">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center">
                                <h2 className='uppercase h2 bernier'>FAQS</h2>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className='text-center gotham-hearfrom mb-3'>
                                    <strong className='font-montserrat'>WHAT IS A POUCH?</strong>
                                    <br />
                                    <span>
                                        This is the name given to the bag-type packaging designed with the highest technology, made with a
                                        lamination suitable for sterilization, which guarantees safety and better preserves the flavor of our beans.</span>
                                </p>
                                <br /><br />

                                <p className='text-center gotham-hearfrom mb-3'>
                                    <strong className='font-montserrat'>
                                        DO ISADORA BEANS HAVE PRESERVATIVES?</strong>
                                    <br />
                                    <span>

                                        Thanks to our vacuum packaging process, it is not necessary to add any type of preservative or additive.</span>
                                </p>
                                <br /><br />

                                <p className='text-center gotham-hearfrom mb-3'>
                                    <strong className='font-montserrat'>IS IT TRUE THAT ISADORA BEANS DO NOT CONTAIN CHOLESTEROL?</strong>
                                    <br />
                                    <span>

                                        It's true! Isadora beans contain no cholesterol or trans fatty acids.
                                    </span>
                                </p>
                                <br /><br />

                                <p className='text-center gotham-hearfrom mb-3'>
                                    <strong className='font-montserrat'>
                                        CAN I EAT ISADORA BEANS IF I WANT TO TAKE CARE OF THE LINE?</strong>
                                    <br />
                                    <span>


                                        Of course! Isadora has a high fiber content, which will help you feel satisfied for longer. Plus, we have low-fat refried beans.
                                    </span>
                                </p>
                                <br /><br />

                                <p className='text-center gotham-hearfrom mb-3'>
                                    <strong className='font-montserrat'>WHAT ARE THE BENEFITS OF CONSUMING ISADORA BEANS?</strong>
                                    <br />
                                    <span>

                                        Isadora beans taste like home made, plus they're nutritious and healthy.
                                    </span>
                                </p>
                                <br /><br />

                                <p className='text-center gotham-hearfrom mb-3'>
                                    <strong className='font-montserrat'>
                                        HOW LONG DO ISADORA BEANS LAST ONCE OPENED?</strong>
                                    <br />
                                    <span>


                                        Like home-made beans, once opened, Isadora beans must remain refrigerated and are recommended for consumption within 7 days.
                                    </span>
                                </p>
                                <br /><br />




                                <p className='text-center gotham-hearfrom mb-3'>
                                    <strong className='font-montserrat'>
                                    CAN I MAKE ENTIRE MEALS WITH ISADORA BEANS?</strong>
                                    <br />
                                    <span>
                                    ¡Por supuesto! Con Isadora puedes echar a volar tu imaginación y preparar platillos deliciosos.
                                    </span>
                                </p>
                                <br /><br />

                                <p className='text-center gotham-hearfrom mb-3'>
                                    <strong className='font-montserrat'>
                                    HOW CAN I CONTACT ISADORA?</strong>
                                    <br />
                                    <span>
                                    We want to know your questions and comments. <Link to="/contact"> Write us here!</Link>
                                    </span>
                                </p>
                                <br /><br />

                            </div>
                        </div>
                        <div className="row mt-5 mb-5">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center">

                            </div>
                        </div>
                    </form>
                </section>
            </>
        )
    }

}

export default FAQS;
