import { useEffect } from 'react';
import { useFetch } from '../../utilities/hooks';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
    Recipes1,
    IngredientsSpoon,
    PreparationBowl,
} from '../../assets/images';
import {
    TypeOfBean,
    SearchBar,
    DifficultyData,
    RelatedRecipesFromRecipes,
    ShareNav,
    Preloader,
} from '../../components';
import './styles.scss';

const SingleRecipe = () => {
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { slug } = useParams();
    const { data } = useFetch(`${BASE_URL}/api/get_recipe/${slug}`);

    useEffect(() => {
        if (data) {
            console.log(data);
        }
    }, [pathname, data]);

    return (
        <>
            {data ? (
                <>
                    <Helmet>
                        <meta charSet='utf-8' />
                        <title>{data.recipe.title} | Isadora Beans</title>
                        <link rel='canonical' href={window.location.href} />
                    </Helmet>
                    <section id='sec2-recipes' className='py-lg-5'>
                        <div className='container py-5'>
                            <div className='row justify-content-end'>
                                <SearchBar />
                            </div>
                            <div className='row'>
                                <div className='col-lg-3 col-md-4 mt-single-recipe'>
                                    <TypeOfBean
                                        productId={data.recipe.product_id}
                                    />
                                </div>
                                <div
                                    id='single-recipe'
                                    className='col-lg-9 col-md-8 d-flex flex-lg-row flex-column'
                                >
                                    <div className='col-lg-6 col-12'>
                                        <DifficultyData
                                            difficulty={data.recipe.difficulty}
                                            time={data.recipe.cooking_time}
                                        />
                                        <img
                                            id='recipe-main-pic'
                                            src={
                                                BASE_URL +
                                                '/storage/recipes/' +
                                                data.recipe.image
                                            }
                                            alt='Receta 1'
                                        />
                                        <div
                                            id='share-box'
                                            className='d-flex align-items-center mt-3'
                                        >
                                            <span className='font-gotham-bold text-black text-uppercase share-span'>
                                                Share Recipe
                                            </span>
                                            <ShareNav
                                                image={
                                                    BASE_URL +
                                                    '/storage/recipes/' +
                                                    data.recipe.image
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        id='data-column'
                                        className='col-lg-6 col-12 mt-single-recipe'
                                    >
                                        <h2 className='text-orangeIs font-bernierd text-lowercase text-center'>
                                            {data.recipe.title}
                                        </h2>
                                        <div className='border-div mb-4'></div>

                                        <div
                                            id='box-ingredients'
                                            className='w-75 mx-auto'
                                        >
                                            <img
                                                src={IngredientsSpoon}
                                                alt='Spoon illustration'
                                                className='mx-auto d-block'
                                            />
                                            <h3 className='text-orangeIs text-uppercase text-center font-gotham-bold'>
                                                Ingredients
                                            </h3>
                                            <div className='border-div mb-4'></div>
                                            <ul id='ingredients-list'>
                                                {data.recipe.ingredient
                                                    .lenght != 0 ? (
                                                    <>
                                                        {data.recipe.ingredient.map(
                                                            (
                                                                ingredient,
                                                                index
                                                            ) => (
                                                                <li
                                                                    key={index}
                                                                    className=''
                                                                >
                                                                    {
                                                                        ingredient.description
                                                                    }
                                                                </li>
                                                            )
                                                        )}
                                                    </>
                                                ) : (
                                                    <div
                                                        style={{
                                                            padding: '1rem',
                                                        }}
                                                    ></div>
                                                )}
                                            </ul>
                                        </div>
                                        <div id='box-preparation'>
                                            <img
                                                src={PreparationBowl}
                                                alt='Spoon illustration'
                                                className='mx-auto d-block'
                                            />
                                            <h3 className='text-orangeIs text-uppercase text-center font-gotham-bold'>
                                                Preparation
                                            </h3>
                                            <div className='border-div mb-4'></div>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: data.recipe
                                                        .preparation,
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id='related-recipes-sec' className='py-5'>
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='d-flex flex-column align-items-center'>
                                    <h2 className='text-white text-center mb-5'>
                                        <span className='font-bernierd text-lowercase'>
                                            Recipes Prepared With
                                        </span>
                                        <br></br>
                                        <span className='font-kellog'>
                                            Original Refried Beans
                                        </span>
                                    </h2>
                                </div>
                            </div>
                            <RelatedRecipesFromRecipes
                                productId={data.recipe.product_id}
                                recipeId={data.recipe.id}
                            />
                        </div>
                    </section>
                </>
            ) : (
                <Preloader />
            )}
        </>
    );
};

export default SingleRecipe;
