import { useEffect } from 'react';
import { IsadorasTaste } from '../../assets/images';
import { TypeOfBean, RecipesResultsGrid, SearchBar } from '../../components';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Results = () => {
    const navigate = useNavigate();
    const { search } = useParams();

    useEffect(() => {
        //console.log(search);
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>Search Results | Isadora Beans</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <section id='RecipesHero'>
                <div className='container'>
                    <div className='row flex-column justify-content-center align-items-center'>
                        <div className=''>
                            <h1 className='mt-5 text-center text-shadow-brown'>
                                <span className='h1-1 text-orangeIs font-bernierd text-lowercase'>
                                    Prepared
                                </span>
                                <br></br>
                                <span className='h1-2 text-white font-kellog'>
                                    Incredible <br></br> recipes
                                </span>
                            </h1>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <p className='text-center text-white mt-3 font-gotham-book'>
                                For an afternoon of movies, a family meal or for
                                lunch every day.
                                <span> Isadora Beans</span> are perfect for any
                                occasion! Discover the rich and simple dishes
                                that you can prepare to satisfy all your
                                cravings.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id='sec2-recipes' className='py-5'>
                <div className='container'>
                    <div className='row justify-content-end'>
                        <SearchBar />
                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4'>
                            <TypeOfBean />
                        </div>
                        <div className='col-lg-9 col-md-8'>
                            <h3 className='text-orangeIs font-gotham-bold text-uppercase'>
                                Search results for {search}
                            </h3>
                            <RecipesResultsGrid search={search} />
                        </div>
                    </div>
                </div>
            </section>
            <section id='recipes-taste-section' className='py-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='text-center mb-4'>
                            <img
                                src={IsadorasTaste}
                                alt="Isadora's Taste Logo"
                                className='w-auto img-fluid'
                            />
                        </div>
                        <div className='col-md-7 col-lg-5 d-flex flex-column align-items-center'>
                            <p className='text-white font-gotham-book text-center mb-5'>
                                Practical tips that people like you share with
                                us to give their <strong>Isadora beans</strong>{' '}
                                a little touch. What's your secret?{' '}
                                <strong>
                                    Share with us the special touch you give to
                                    your dishes
                                </strong>
                                .
                            </p>
                            <button className='orange-btn'>Learn More</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Results;
