import { useState, useEffect, useContext } from 'react';
import { Context } from '../../components/Store';
import { useNavigate } from 'react-router-dom';
import { timeOut } from '../../utilities/setTimeout';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark as Cross } from '@fortawesome/free-solid-svg-icons';
import './style.scss';

const ModalSearch = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [value, setValue] = useState('');
    const navigateTo = useNavigate();
    const { dispatch } = useContext(Context);
    const hideModal = e => {
        if (!e.currentTarget.classList.contains('modal-search--show')) {
            dispatch({ modalSearchIsOpen: false });
            document.documentElement.removeAttribute('style');
        }
    };
    const search = async e => {
        e.preventDefault();
        if (value) {
            setIsVisible(false);
            await timeOut(500);
            navigateTo(`results/${value}`);
        }
    };

    useEffect(() => {
        (async () => {
            document.documentElement.style.overflow = 'hidden';
            await timeOut(100);
            setIsVisible(true);
        })();
    }, []);

    return (
        <div
            className={`modal-search${
                isVisible ? ' modal-search--show' : ''
            } d-flex flex-column justify-content-center`}
            onTransitionEnd={hideModal}
        >
            <Container>
                <form className='d-flex align-items-center' onSubmit={search}>
                    <input
                        type='text'
                        className={`modal-search__input${
                            isVisible ? ' modal-search__input--show' : ''
                        } me-3 p-2 flex-grow-1`}
                        autoFocus
                        onChange={e => setValue(e.target.value)}
                        onTransitionEnd={e => e.stopPropagation()}
                    />
                    <FontAwesomeIcon
                        icon={Cross}
                        className='fa-lg modal-search__close'
                        role='button'
                        aria-label='Close Modal'
                        onClick={setIsVisible.bind(null, false)}
                    />
                </form>
            </Container>
        </div>
    );
};

export default ModalSearch;
