import { navigation } from '../../utilities/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const SocialNav = styled.ul`
    width: 140px;
`;

const SocialMediaNav = () => {
    const { socialMedia } = navigation;
    return (
        <SocialNav className='d-flex justify-content-between ps-3'>
            {socialMedia.map((item, i) => (
                <li key={i} className='circle-btn'>
                    <a
                        href={item.link}
                        aria-label={`go to ${item.label}`}
                        target='_blank'
                        rel='noreferrer noopener'
                    >
                        <FontAwesomeIcon icon={item.icon} className='fa-lg' />
                    </a>
                </li>
            ))}
        </SocialNav>
    );
};

export default SocialMediaNav;
