import Router from './views/Router';
import { Store } from './components';
import './styles/main.scss';

const App = () => (
    <Store>
        <Router />
    </Store>
);

export default App;
