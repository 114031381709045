import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Recipes1 } from '../../assets/images';
import { DifficultyData } from '../';
import { Preloader } from '../../components';
import './styles.scss';

const RecipesGrid = props => {
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(null);
    const { pathname } = useLocation();

    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = '/api/get_recipes/' + props.product;

    const getRecipes = async () => {
        const res = await fetch(BASE_URL + endpoint, {
            method: 'GET',
        });
        const data = await res.json();
        setData(data);
    };

    useEffect(() => {
        getRecipes();
    }, [pathname]);

    return (
        <div className='row'>
            {data?.recipes ? (
                data?.recipes.length == 0 ? (
                    <>No results</>
                ) : (
                    <>
                        {data.recipes.map((recipe, i) => {
                            return (
                                <div
                                    className='col-lg-4 col-md-6 recipe-card font-gotham-bold'
                                    key={i}
                                >
                                    <Link to={'/recipe/' + recipe.slug}>
                                        <div className='img-box'>
                                            <img
                                                src={
                                                    BASE_URL +
                                                    '/storage/recipes/' +
                                                    recipe.image
                                                }
                                                alt=''
                                                className='img-fluid'
                                            />
                                        </div>
                                        <DifficultyData
                                            difficulty={recipe.difficulty}
                                            time={recipe.cooking_time}
                                        />
                                        <h3 className='text-orangeIs text-uppercase text-center'>
                                            {recipe.title}
                                        </h3>
                                        <p
                                            className=''
                                            dangerouslySetInnerHTML={{
                                                __html: recipe.description,
                                            }}
                                        ></p>
                                    </Link>
                                </div>
                            );
                        })}
                    </>
                )
            ) : (
                <Preloader />
            )}
        </div>
    );
};

export default RecipesGrid;
