import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';


const DifficultyData = (props) => {

    return (
                    <div className='d-flex justify-content-between my-2 recipe-data font-gotham-bold'>
                        <div className='d-flex  flex-wrap col-8'>
                            <div className='d-inline-block'>
                                {
                                    (props.difficulty == "Media")
                                    ?
                                    <>
                                        {[...Array(5)].map((cuadrado,i)=><span
                                            key={i}
                                            className='difficulty-square full-square'
                                        ></span>)}
                                        {[...Array(2)].map((cuadrado,i)=><span
                                            key={i}
                                            className='difficulty-square'
                                        ></span>)}
                                    </>
                                    :
                                    <>
                                        {[...Array(6)].map((cuadrado,i)=><span
                                            key={i}
                                            className='difficulty-square full-square'
                                        ></span>)}
                                    </>
                                }
                            </div>
                            <div>
                                <span className='difficulty-label pr-2'> Difficulty </span>
                                <span className='difficulty-level text-uppercase text-orangeIs'>
                                    {
                                        (props.difficulty == "Media") ? "MEDIUM" : "HARD"
                                    }
                                </span>
                            </div>
                        </div> 
                        <span className='text-orangeIs time-label text-end align-self-end col-4'><FontAwesomeIcon icon={faClock}/> {props.time} min</span>
                    </div>
                    
    );
};

export default DifficultyData;