import React from 'react';
import { Carrito } from '../../assets/images';
import { Helmet } from 'react-helmet';
import ModalWhereToBuy from '../../components/ModalWhereToBuy';
import LocationsWhereToBuy from '../../components/LocationsWhereToBuy';

import './styles.scss';

class WhereBuy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(modalOpen) {
        this.setState({ modalOpen });
    }

    getOS() {
        var userAgent = window.navigator.userAgent,
            platform =
                window.navigator?.userAgentData?.platform ||
                window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (/Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }

    render() {
        return (
            <>
                <section id='dondeComprarSection'>
                    <Helmet>
                        <meta charSet='utf-8' />
                        <title>Where To Buy | Isadora Beans</title>
                        <link rel='canonical' href={window.location.href} />
                    </Helmet>
                    <div className='container mt-5 mb-3'>
                        <div className='row d-flex justify-content-center'>
                            <h2 className='text-center h3 font-gotham-bold'>
                                We use the highest
                                <span className='uppercase'>
                                    {' '}
                                    quality ingredients{' '}
                                </span>
                                in every bags of Isadora&#174;
                            </h2>
                            <h2 className='text-center h3 font-gotham-bold'>
                                We invite you to know all our products.
                            </h2>
                            <h2 className='text-center h3 font-gotham-bold'>
                                Find us in your city.
                            </h2>
                        </div>
                    </div>

                    <div id='wb-sec-2' className='mb-5 container'>
                        <div className='row align-items-center'>
                            <div className='col-md-6 col-lg-4'>
                                <img
                                    src={Carrito}
                                    alt='Carrito'
                                    title='Carrito de compras'
                                />
                            </div>
                            <div
                                id='img-cart-title'
                                className='col-md-6 col-lg-4'
                            >
                                <h3 className='text-center font-bernierd text-lowercase text-brownIs align-items-center'>
                                    <span>Where</span>{' '}
                                    <span className='mx-auto text-white span-2 justify-content-center'>
                                        to buy
                                    </span>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div id='wb-btns-box' className='container'>
                        <div className='row d-flex justify-content-center align-items-center'>
                            <div className='col-md-5 col-lg-4 d-flex justify-content-center mb-3 mb-md-0'>
                                <button
                                    id='wb-search'
                                    onClick={() => this.handleChange(false)}
                                >
                                    City, State or Zip Code
                                </button>
                            </div>
                            <div className='col-md-5 col-lg-4 d-flex justify-content-center'>
                                <button
                                    className={`orange-btn d-flex flex-column ${
                                        this.state.modalOpen
                                            ? 'modal-open'
                                            : 'modal-close'
                                    }`}
                                    id='btn-secondary-custom'
                                    onClick={() => this.handleChange(true)}
                                >
                                    <div
                                        className='d-flex flex-column w-100'
                                        id={
                                            this.getOS() == 'Mac OS'
                                                ? 'openModalButton'
                                                : undefined
                                        }
                                    >
                                        <span>Buy Online</span>
                                        <div className='line-deco-btn mx-auto'></div>
                                        <div className='wb__trackbar-box'></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        {this.state.modalOpen && (
                            <ModalWhereToBuy
                                OpenClose
                                clickHandler={this.handleChange.bind(
                                    null,
                                    false
                                )}
                            />
                        )}
                    </div>
                    <LocationsWhereToBuy />
                </section>
            </>
        );
    }
}

export default WhereBuy;
