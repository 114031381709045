import { useContext } from 'react';
import { Context } from '../Store';
import { MobileNav, DesktopNav } from './components/';
import { useMediaQuery } from '../../utilities/hooks';
import { navigation } from '../../utilities/navigation';
import './styles.scss';

const Header = () => {
    const {
        state: { mainNavIsOpen },
    } = useContext(Context);
    const breakpointLg = useMediaQuery('lg');
    const { list, isadoraLogo, cart } = navigation;
    return (
        <header className='header position-relative'>
            {!breakpointLg ? (
                <MobileNav
                    data={{
                        isadoraLogo,
                        list: [...list, cart],
                        mainNavIsOpen,
                    }}
                />
            ) : (
                <DesktopNav data={{ isadoraLogo, list }} />
            )}
        </header>
    );
};

export default Header;
