import { faMagnifyingGlass as Search } from '@fortawesome/free-solid-svg-icons';
import {
    faTwitter as Twitter,
    faFacebookF as Facebook,
    faInstagram as Instagram,
    faPinterestP as Pinterest,
} from '@fortawesome/free-brands-svg-icons';
import { LogoVerdeValleMain, EspFlag, WhereToBuy } from '../assets/images';

export const navigation = {
    list: [
        { text: 'ABOUT US', path: 'about-us' },
        { text: 'PRODUCTS', path: 'products' },
        { text: 'BEANS, AS A SUPERFOOD', path: 'beans-a-superfood' },
        { text: 'RECIPES', path: 'recipes' },
        { text: 'NEWS', path: 'news' },
    ],
    isadoraLogo: {
        src: LogoVerdeValleMain,
        path: '/',
        alt: 'Isadora',
    },
    socialMedia: [
        {
            icon: Facebook,
            label: 'Facebook',
            link: 'https://www.facebook.com/IsadoraBeans/',
        },
        {
            icon: Instagram,
            label: 'Instagram',
            link: 'https://www.instagram.com/isadorabeans/?hl=es',
        },
        {
            icon: Pinterest,
            label: 'Pinterest',
            link: ' https://www.pinterest.com.mx/IsadoraBeans/',
        },
    ],
    shareMedia: [
        {
            icon: Facebook,
            label: 'Facebook',
        },
        {
            icon: Twitter,
            label: 'Twitter',
        },
        {
            icon: Pinterest,
            label: 'Pinterest',
        },
    ],
    language: {
        text: 'ES',
        src: EspFlag,
        link: 'https://frijolesisadora.com',
        alt: 'Spanish Language',
    },
    search: {
        icon: Search,
        label: 'Search',
    },
    cart: {
        text: 'WHERE TO BUY',
        path: 'where-to-buy',
        icon: WhereToBuy,
        alt: 'Cart',
    },
};
