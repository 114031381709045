export const footerLinks = [
    { pathname: '/' },
    {
        pathname: '/about-us',
        text: 'About Us',
        type: 'navigation',
    },
    {
        pathname: '/products',
        text: 'Products',
        type: 'navigation',
    },
    {
        pathname: '/beans-a-superfood',
        text: 'Beans, as a Superfood',
        type: 'navigation',
    },
    {
        pathname: '/recipes',
        text: 'Recipes',
        type: 'navigation',
    },
    {
        pathname: '/news',
        text: 'News',
        type: 'navigation',
    },
    {
        pathname: '/where-to-buy',
        text: 'Where to Buy',
        type: 'navigation',
    },
    {
        pathname: '/contact',
        text: 'Contact Us',
        type: 'navigation',
    },
    {
        pathname: '/products/original-recipe-refired-beans',
        text: 'Original Refried Beans',
        type: 'products',
    },
    {
        pathname: '/products/Black-Refried-beans',
        text: 'Black Refried Beans',
        type: 'products',
    },
    {
        pathname: '/products/mayocoba-refried-beans',
        text: 'Mayocoba Refried Beans',
        type: 'products',
    },
    {
        pathname: '/products/Low-Fat-Refried-Beans',
        text: 'Fat Free Refried Beans',
        type: 'products',
    },
    {
        pathname: '/products/Traditional-Refried-Beans',
        text: 'Traditional Refried Beans',
        type: 'products',
    },
    {
        pathname: '/products/Whole-Black-Beans',
        text: 'Whole Black Beans',
        type: 'products',
    },
    {
        pathname: '/products/Whole-Mayocoba-Beans',
        text: 'Whole Mayocoba Beans',
        type: 'products',
    },
    {
        pathname: '/products/Whole-Pinto-Beans',
        text: 'Whole Pinto Beans',
        type: 'products',
    },
];
