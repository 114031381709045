import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { LineaVVContact } from '../../assets/images';
import './styles.scss';

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: false,
            success: false,
            btnDissabled: false,
            messageError: false,
            name: '',
            last_name: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            country: '',
            reason: '',
            comments: '',
            privacy: false,
            ads: true,
            messagePhoneError: false,
            messageEmailError: false,
            messageErrorIP: false,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        console.log(process.env.REACT_APP_API_BASE_URL);
    }

    BASE_URL = process.env.REACT_APP_API_BASE_URL;

    async handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        await this.setState({
            [name]: value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({messageError: false});
        this.setState({ messageEmailError: false });
        this.setState({ messagePhoneError: false });

        if(this.state.name == '' ||
            this.state.last_name == '' ||
            this.state.email == '' ||
            this.state.phone == '' ||
            this.state.address == '' ||
            this.state.city == '' ||
            this.state.country == '' ||
            this.state.reason == '' ||
            this.state.comments == '' ||
            this.state.privacy == '')
        {
            await this.setState({ messageError: true });
            return;
        }

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))
        {
            console.log("error email");
            await this.setState({ messageEmailError: true });
            return;
        }

        if (!/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(this.state.phone))
        {
            console.log("phone error");
            await this.setState({ messagePhoneError: true });
            return;
        }


        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: this.state.name,
                last_name: this.state.last_name,
                email: this.state.email,
                phone: this.state.phone,
                address: this.state.address,
                city: this.state.city,
                country: this.state.country,
                reason: this.state.reason,
                message: this.state.comments,
                privacy: this.state.privacy,
                accepts_ads: this.state.ads,
                lang: "en",
            }),
        }

        fetch(this.BASE_URL+"/api/contact", options)
        .then((response) => response.json())
        .then(
            (result) => {
                if(result.Status == "Not a valid email provider")
                {
                    this.setState({ messageEmailError: true });
                    return;
                }
                if(result.Status == "Validation failed"){
                    this.setState({ messageError: true });
                    return;
                }
                if(result.Status == "Contact Not Saved, IP already used")
                {
                    this.setState({ messageErrorIP: true });
                    return;
                }
                else{
                    this.setState({btnDissabled: true});
                    //this.setState({errors: false});
                    this.setState({success: true});
                    this.setState({success: true});
                    this.setState({btnDissabled: true})
                    return;
                }
            },
            (error) => {
                this.setState({btnDissabled: true});
                this.setState({success: true});
                this.setState({success: true});
                this.setState({btnDissabled: true})
                //console.log(error);
                return;
            });
    }

    render() {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Contact Us | Isadora Beans</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <section id='contactSection'>
                    <div className="container brown p-5" id="mainFormContact">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center">
                                <h2 className='lowercase h2 bernier main-contact-title'>congrats or comments?</h2>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center">
                                <p className='text-center gotham-hearfrom'>
                                    <strong className='font-montserrat'>We want to hear from you! </strong>Contact us and let
                                    us know<br /> your thoughts about our products and recipes.
                                </p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center">

                                <a className="alx-link-telefono" href="tel:+8006346130">
                                <img src={LineaVVContact} alt="Customer Care Phone 800 634 6130" className='svgLineaCustomerCare' />
                                </a>

                                



                            </div>
                        </div>
                        <div className="row mt-5 mb-5">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center">

                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }

}

export default Contact;
