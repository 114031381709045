import { Row, Col } from 'react-bootstrap';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { LogoVerdeValleMain } from '../../assets/images';
import { SocialMedia } from '../';
import { footerLinks } from './footer-links';
import { navigateTo } from '../../utilities/hooks';
import './styles.scss';

const Footer = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <>
            <footer>
                <div className='py-5 container'>
                    <div className='row'>
                        <div className='justify-content-center justify-content-lg-between d-flex flex-column flex-lg-row text-white'>
                            <Link
                                to={footerLinks[0].pathname}
                                className='text-center'
                                onClick={e =>
                                    navigateTo(
                                        e,
                                        pathname,
                                        footerLinks[0].pathname,
                                        navigate
                                    )
                                }
                            >
                                <img
                                    src={LogoVerdeValleMain}
                                    className='img-fluid logo-footer mb-4 text-center mx-auto mx-lg-0 d-inline-block align-self-start'
                                    alt='Isadora'
                                />
                            </Link>
                            <div className='mb-4 mb-lg-0 text-center text-lg-start text-decoration-none d-flex flex-column'>
                                <h2 className='font-montserrat'>MENU</h2>
                                {footerLinks
                                    .filter(link => link.type === 'navigation')
                                    .map((link, i) => (
                                        <NavLink
                                            to={link.pathname}
                                            className='font-arial text-decoration-none text-white font-weight-bold'
                                            onClick={e =>
                                                navigateTo(
                                                    e,
                                                    pathname,
                                                    link.pathname,
                                                    navigate
                                                )
                                            }
                                            key={i}
                                        >
                                            {link.text}
                                        </NavLink>
                                    ))}
                            </div>
                            <div className='mb-4 mb-lg-0 text-center text-lg-start d-flex flex-column'>
                                <h2 className='font-montserrat'>PRODUCTS</h2>
                                {footerLinks
                                    .filter(link => link.type === 'products')
                                    .map((link, i) => (
                                        <NavLink
                                            to={link.pathname}
                                            className='font-arial text-decoration-none text-white font-weight-bold'
                                            onClick={e =>
                                                navigateTo(
                                                    e,
                                                    pathname,
                                                    link.pathname,
                                                    navigate
                                                )
                                            }
                                            key={i}
                                        >
                                            {link.text}
                                        </NavLink>
                                    ))}
                            </div>
                            <div>
                                <h2 className='text-center font-montserrat'>
                                    FOLLOW US
                                </h2>
                                <div id='div-sm-footer' className='mx-auto'>
                                    <SocialMedia />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='copyright-box'>
                    <div className='container'>
                        <Row>
                            <Col className='d-flex flex-column-reverse flex-md-row justify-content-center py-3 text-white text-center'>
                                <span className='p-2 font-weight-bold'>
                                    2022 Copyright Isadora Beans
                                </span>
                                <span className='d-none d-md-inline py-2'>
                                    |
                                </span>
                                <Link
                                    className='p-2 text-white text-decoration-none font-weight-bold'
                                    to='/privacy-policy'
                                    onClick={e =>
                                        navigateTo(
                                            e,
                                            pathname,
                                            '/privacy-policy',
                                            navigate
                                        )
                                    }
                                >
                                    Privacy Policy
                                </Link>
                                <span className='d-none d-md-inline py-2'>
                                    |
                                </span>
                                <Link
                                    className='p-2 text-white text-decoration-none font-weight-bold'
                                    to='/faqs'
                                    onClick={e =>
                                        navigateTo(
                                            e,
                                            pathname,
                                            '/faqs',
                                            navigate
                                        )
                                    }
                                >
                                    FAQ's
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
