import styled from 'styled-components';

const SVG = styled.svg`
    width: 0;
    height: 0;
`;

const Filter = () => {
    return (
        <SVG>
            <defs>
                <filter id='gooey'>
                    <feGaussianBlur
                        in='SourceGraphic'
                        stdDeviation='10'
                        result='blur'
                    />
                    <feColorMatrix
                        in='blur'
                        mode='matrix'
                        values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -8'
                        result='gooey'
                    />
                    <feBlend in='SourceGraphic' in2='gooey' />
                </filter>
            </defs>
        </SVG>
    );
};

export default Filter;
