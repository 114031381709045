import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Helmet } from 'react-helmet';
import {
    PlateOfBeans,
    bgHome,
    sfIco1,
    sfIco2,
    sfIco3,
    sfIco4,
    sfIco5,
    sfIco6,
    sfLineN1,
    sfLineN2,
    sfPlatoHome,
    ourBeansIco1,
    ourBeansIco2,
    ourBeansIco3,
    ourBeansIco4,
    ourBeansIco5,
    ourBeansPlato1,
    ourBeansPlato2,
    ourBeansPlato3,
    sfBolsas,
    perfectForAll3,
    perfectForAll2,
    perfectForAll1,
} from '../../assets/images';
import './styles.scss';
import "animate.css/animate.min.css";

class BeansASuperfood extends React.Component {
    render() {
        return (
            <>
                <section id='beans-super-f'>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Beans As A Superfood | Isadora Beans</title>
                        <link rel="canonical" href={window.location.href} />
                    </Helmet>
                    <img
                        src={bgHome}
                        className='bs-f-top-bg'
                        alt=''
                        width='100%'
                    />
                    <div className='container'>
                        <AnimationOnScroll duration={2} animateOnce animateIn='animate__animated animate__backInDown'>
                            <div className='sf-plato-home hach'>
                                <h1>beans</h1>
                                <h2>as a superfood</h2>
                            </div>
                        </AnimationOnScroll>
                        
                            <div className='sf-ico-1'>
                                <AnimationOnScroll duration={2} animateOnce animateIn='animate__animated animate__backInLeft'>
                                    <img src={sfIco1} className='' alt='' width='130' />
                                    <p>PRESERVATIVES </p>
                                    <h4>free</h4>
                                    <img
                                        src={sfLineN1}
                                        alt=''
                                        width='130'
                                        className='mt10'
                                    />
                                </AnimationOnScroll>
                            </div>

                            <div className='sf-ico-2'>
                                <AnimationOnScroll duration={2} animateOnce animateIn='animate__animated animate__backInLeft'>
                                    <img src={sfIco2} className='' alt='' width='130' />
                                    <h4>low </h4>
                                    <p>GLYCEMIC INDEX</p>

                                    <img
                                        src={sfLineN1}
                                        alt=''
                                        width='130'
                                        className='mt10'
                                    />
                                </AnimationOnScroll>
                            </div>
                        
                        <div className='sf-plato-home'>
                            <AnimationOnScroll duration={2} animateOnce animateIn='animate__animated animate__backInDown'>
                                <img src={PlateOfBeans} className='' alt='' />
                            </AnimationOnScroll>
                        </div>

                        <div className='sf-plato-home-bot'>
                            <div className='sf-ico-5'>
                                <AnimationOnScroll duration={2} animateOnce animateIn='animate__animated animate__backInUp'>
                                    <img
                                        src={sfIco5}
                                        className=''
                                        alt=''
                                        width='130'
                                    />
                                    <h4>high</h4>
                                    <p>FIBER </p>
                                    <img
                                        src={sfLineN1}
                                        alt=''
                                        width='130'
                                        className='mt10'
                                    />
                                </AnimationOnScroll>
                            </div>

                            <div className='sf-ico-6'>
                                <AnimationOnScroll duration={2} animateOnce animateIn='animate__animated animate__backInUp'>
                                    <img
                                        src={sfIco6}
                                        className=''
                                        alt=''
                                        width='130'
                                    />
                                    <h4>power</h4>
                                    <p>SOURCE</p>
                                    <img
                                        src={sfLineN1}
                                        alt=''
                                        width='130'
                                        className='mt10'
                                    />
                                </AnimationOnScroll>
                            </div>
                        </div>

                        <div className='sf-ico-3'>
                            <AnimationOnScroll duration={2} animateOnce animateIn='animate__animated animate__backInRight'>
                                <img src={sfIco3} className='' alt='' width='130' />
                                <p>CHOLESTEROL </p>
                                <h4>free </h4>
                                <img
                                    src={sfLineN1}
                                    alt=''
                                    width='130'
                                    className='mt10'
                                />
                            </AnimationOnScroll>
                        </div>

                        <div className='sf-ico-4'>
                            <AnimationOnScroll duration={2} animateOnce animateIn='animate__animated animate__backInRight'>
                                <img src={sfIco4} className='' alt='' width='130' />
                                <h4 className='boxxfa'>antioxidants </h4>
                                <img
                                    src={sfLineN1}
                                    alt=''
                                    width='130'
                                    className='mt10'
                                />
                            </AnimationOnScroll>
                        </div>
                    </div>

                    <div className='container our-beans'>
                        <img
                            src={sfLineN2}
                            alt=''
                            width='130'
                            className='mb30'
                        />
                        <h3>
                            <span>our</span> <br /> beans
                        </h3>
                        <p>
                            <span>We use beans of</span>{' '}
                            <b>
                                the highest quality and grown in Mexican fields.
                            </b>
                        </p>
                        <AnimationOnScroll duration={2} animateOnce animateIn='animate__animated animate__backInUp'>
                            <img src={sfBolsas} alt='' className='mt10' />
                        </AnimationOnScroll>
                    </div>
                </section>

                <section id='bolsas'>
                    <div className='container'>
                        <div className='bolsas-icons'>
                            <div>
                                <img
                                    src={ourBeansIco1}
                                    alt=''
                                    className='mt10'
                                />
                            </div>
                            <div>
                                <img
                                    src={ourBeansIco2}
                                    alt=''
                                    className='mt10'
                                />
                            </div>
                            <div>
                                <img
                                    src={ourBeansIco3}
                                    alt=''
                                    className='mt10'
                                />
                            </div>
                            <div>
                                <img
                                    src={ourBeansIco4}
                                    alt=''
                                    className='mt10'
                                />
                            </div>
                            <div>
                                <img
                                    src={ourBeansIco5}
                                    alt=''
                                    className='mt10'
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section id='occasions'>
                    <div className='container'>
                        <div className='dish-block'>
                            <AnimationOnScroll duration={2} animateOnce animateIn='animate__animated animate__backInLeft'>
                                <img src={perfectForAll1} alt='' className='mt10' />
                            </AnimationOnScroll>
                            <AnimationOnScroll duration={2} animateOnce animateIn='animate__animated animate__backInUp'>
                                <img src={perfectForAll2} alt='' className='mt10' />
                            </AnimationOnScroll>
                            <AnimationOnScroll duration={2} animateOnce animateIn='animate__animated animate__backInRight'>
                                <img src={perfectForAll3} alt='' className='mt10' />
                            </AnimationOnScroll>
                        </div>

                        <h3>
                            <span>Perfect for</span>
                            <br />
                            all occasions
                        </h3>

                        <p>
                            Because of their{' '}
                            <span>
                                delicious flavor and high nutritional
                                attributes,
                            </span>{' '}
                            beans are present in all Mexican homes. They can be
                            part of the daily meal or bring families together at
                            parties and celebrations.
                        </p>
                    </div>
                </section>
            </>
        );
    }
}

export default BeansASuperfood;
